import {useReducer, useEffect} from 'react';
import {useRoute} from 'react-router5'
import { shouldUpdateNode } from 'router5-transition-path'
import Filters from './Filters';
import AlbumList from './AlbumList';

export const ALBUMS_EMPTY = {items: [], page: 0, more: false};
export function browserReducer(state, action) {
  switch(action.type) {
  case 'LOAD_ALBUMS': {
    const {more} = action.payload;
    const items = state.albums.items.concat(action.payload.items);
    return {...state, albums: {...state.albums, items, more}}}
  case 'SET_FILTER': {
    const filter = action.payload;
    return {...state, filter}}
  case 'SET_FILTERS': {
    const albums = ALBUMS_EMPTY;
    const filters = action.payload;
    return {...state, albums, filters, filter: filters.filter}}
  case 'SET_PAGE': {
    const albums = {...state.albums, page: action.payload, more: false};
    return {...state, albums}}
  default: throw new Error(`Unknown action type: ${action.type}`);
  }
}

export default function Browser({dispatch, scrollRef}) {
  const { router } = useRoute();
  const { params } = router.getState();
  const INIT = {albums: ALBUMS_EMPTY, error: false, filters: params, filter: params.filter};
  const [state, dispatchBrowser] = useReducer(browserReducer, INIT);

  useEffect(() => router.subscribe(({ route, previousRoute }) => {
    const shouldUpdate = shouldUpdateNode("browser")(route, previousRoute);
    if (shouldUpdate) dispatchBrowser({type:'SET_FILTERS', payload: route.params});
  }), [])

  return (
    <>
      <Filters {...state} dispatch={dispatchBrowser} />
      <AlbumList {...state} dispatch={dispatchBrowser} playerDispatch={dispatch} scrollRef={scrollRef} />
    </>
  );
}
