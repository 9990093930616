import React from 'react';

export default class MediaSession extends React.Component {
  updateMetadata() {
    if (!('mediaSession' in navigator)) {
      return;
    }
    const {title, artist, album, cover} = this.props;
    const artwork = cover ? [{src: cover}] : undefined;
    navigator.mediaSession.metadata = new window.MediaMetadata({title, artist, album, artwork});
  }
  updateEvents() {
    if (!('mediaSession' in navigator)) {
      return;
    }
    const {mediaSession} = navigator;
    const {onPlay, onPause, onSeekBackward, onSeekForward, onPreviousTrack, onNextTrack} = this.props;

    mediaSession.setActionHandler('play', onPlay);
    mediaSession.setActionHandler('pause', onPause);
    mediaSession.setActionHandler('seekbackward', onSeekBackward);
    mediaSession.setActionHandler('seekforward', onSeekForward);
    mediaSession.setActionHandler('previoustrack', onPreviousTrack);
    mediaSession.setActionHandler('nexttrack', onNextTrack);
  }
  componentDidMount() {
    this.updateMetadata();
    this.updateEvents();
  }
  componentDidUpdate(prevProps) {
    this.updateMetadata();
    this.updateEvents();
  }
  componentWillUnmount() {
    if (!('mediaSession' in navigator)) {
      return;
    }
    const {mediaSession} = navigator;
    mediaSession.metadata = null;
    mediaSession.setActionHandler('play', null);
    mediaSession.setActionHandler('pause', null);
    mediaSession.setActionHandler('seekbackward', null);
    mediaSession.setActionHandler('seekforward', null);
    mediaSession.setActionHandler('previoustrack', null);
    mediaSession.setActionHandler('nexttrack', null);
  }

  render() {
    return null;
  }
}
