import { useRoute } from 'react-router5'
import { useRef } from 'react';
import AsyncSelect from 'react-select/async';
import {getCategory, request} from 'src/Api';
import './Filters.css';

export const CATEGORIES = [{cat: 'artist', title: 'Artists'},
                           {cat: 'album', title: 'Album', hidden: true},
                           {cat: 'year', title: 'Years'},
                           {cat: 'genre', title: 'Genres'},
                           {cat: 'publisher', title: 'Labels'},
                           {cat: 'country', title: 'Countries'},
                           {cat: 'type', title: 'Album types', hidden: true},
                           {cat: 'status', title: 'Album statuses', hidden: true}];

function handleLoadOptions(cat, q) {
  const pageSize = 10000
  const params = {filter:q}
  params.offset = 0;
  params.limit = pageSize;
  const {url, options} = getCategory(cat, params);
  return request(url, options)
}

function handleCategoryChange(router, cat, value) {
  const params = router.getState().params;
  router.navigate('browser', {...params, [cat]:(value || null)});
}

function handleFilterChange(dispatch, router, timeout, value) {
  if (timeout.current) clearInterval(timeout.current);
  timeout.current = setTimeout(() => handleCategoryChange(router, 'filter', value), 500);
  dispatch({type: 'SET_FILTER', payload: value});
}

export default function Filters({dispatch, filters, filter}) {
  const timeout = useRef();
  const { router } = useRoute();

  return (
    <div className="Filters">
      {CATEGORIES.map(({cat, title, hidden}) => (!hidden &&
        <div className="section" key={cat}>
          <AsyncSelect
            isClearable
            cacheOptions
            placeholder={title}
            defaultValue={filters[cat] && {item: filters[cat]}}
            defaultOptions
            loadOptions={(q) => handleLoadOptions(cat, q)}
            getOptionLabel={(i)=>i.count ? `${i.item} (${i.count})` : i.item}
            getOptionValue={(i)=>i.item}
            onChange={(e)=>handleCategoryChange(router, cat, (e && e.item))}
            />
        </div>
      ))}
      <div className="Latest">
      <label>Latest<input
             type="checkbox"
             checked={ !filters.alpha }
             onChange={(e) => handleCategoryChange(router, 'alpha', !e.target.checked)} /></label>
      </div>
      <div className="Filter">
        <input
          type="text"
          placeholder="Search albums"
          value={filter || ''}
          onChange={(e)=>handleFilterChange(dispatch, router, timeout, e.target.value)} />
      </div>
    </div>
  );
}
