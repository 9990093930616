import {useReducer} from 'react';
import './UserInfo.css';
import logo from 'src/chad-logo-256.png';
import {useFetch, initialState, fetchReducer, getUser} from 'src/Api';

function UserInfo() {
  const [{loading, error, data}, dispatch] = useReducer(fetchReducer, initialState());
  useFetch(getUser(), dispatch);

  const msg = data ? `${data.first_name} ${data.last_name}`
        : (error ? `${error.message}` : (loading ? 'Loading' : ''));
  const className = data ? 'UserInfo-name' : (error ? 'UserInfo-error' : (loading ? 'UserInfo-loading': ''));
  const src = data ? data.photo_url : logo;
  const alt = data ? data.username : 'Loading';

  return (
    <div className="UserInfo">
      <span className={className}>{msg}</span>
      <img src={src} alt={alt} />
    </div>
  )
}

export default UserInfo;
