import React from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider } from 'react-router5'
import './index.css';
import App from './components/App/App';
import createRouter from './create-router'
import reportWebVitals from './reportWebVitals';


const router = createRouter()

router.start(() => ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router}><App /></RouterProvider>
  </React.StrictMode>,
  document.getElementById('root')
));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
	registration.unregister();
    });
}
