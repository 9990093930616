import loggerPlugin from 'router5-plugin-logger'
import browserPlugin from 'router5-plugin-browser'
import routes from './routes'
const r5 = require('router5')

export default function configureRouter() {
  const router = r5.createRouter(routes, {
    defaultRoute: 'browser',
    caseSensitive: true,
    urlParamsEncoding: 'uri'
  })

  router.usePlugin(loggerPlugin)
  router.usePlugin(browserPlugin())

  return router
}
