export function formatDuration(duration, text = false) {
  duration = Math.floor(duration);
  var seconds = duration % 60;
  const minutes = Math.floor(duration / 60);
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return `${minutes}${text?'m':''}:${seconds}${text?'s':''}`;
}

export function getQueryString(params) {
  var esc = encodeURIComponent;
  return Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
}

const SUBST = {
  ':':'-colon-',
  '/':'-slash-',
  '\\':'rslash',
  '?':'-qmark-',
  '#':'-hash-',
  '[':'-obr-',
  ']':'-cbr-',
  '@':'-at-',
  '~':'-tilda-',
  ' ':'_',
  '_':'-und-',
  '‐':'-hyphen-',
  '-':'-dash-'
};
const UNSUBST = Object.assign({}, ...Object.entries(SUBST).map(([a,b]) => ({[b]: a})))
const FORW = new RegExp(/[:/\\?#[\]@~ _‐-]/, 'g');
const BACK = new RegExp(`(${Object.values(SUBST).join('|')})`,'g');
//export function slugify(str) { return str && encodeURI(str.replace(FORW, e=>SUBST[e])) }
//export function unslugify(str) { return str && decodeURI(str).replace(BACK, e=>UNSUBST[e]) }
export function slugify(str) { return str && str.replace(FORW, e=>SUBST[e]) }
export function unslugify(str) { return str && str.replace(BACK, e=>UNSUBST[e]) }
