import {useRef} from 'react';
import {useRouteNode} from 'react-router5'
import Browser from './Browser';
import ArtistPage from './ArtistPage';
import AlbumPage from './AlbumPage';

export default function Main({dispatch}) {
  const scrollRef = useRef(null);
  const { route } = useRouteNode('');

  let content = <h1>Page not found!</h1>;
  if (route.name === 'browser')
    content = <Browser dispatch={dispatch} scrollRef={scrollRef} />
  else if (route.name === 'artist')
    content = <ArtistPage dispatch={dispatch} scrollRef={scrollRef} />
  else if (route.name === 'album')
    content = <AlbumPage dispatch={dispatch} scrollRef={scrollRef} />

  return (
    <main ref={scrollRef}>{ content }</main>
  )
}
