import './Settings.css';
import Select from 'react-select';

const THEMES = [
  {value: 'light', label: 'Light'},
  {value: 'dark', label: 'Dark'}
];

const LAYOUTS = [
  {value: 'bottom', label: 'Bottom controls'},
  {value: 'left', label: 'Left controls'},
  {value: 'right', label: 'Right controls'},
];

export default function Settings({state, dispatch}) {
  const theme = THEMES.find(e => e.value === state.theme);
  const layout = LAYOUTS.find(e => e.value === state.layout);

  return (
    <div className="Settings">
      <Select className="Settings-theme" defaultValue={theme} options={THEMES} onChange={(e)=>dispatch({type: 'THEME_SET', payload: e.value})} />
      <Select className="Settings-layout" defaultValue={layout} options={LAYOUTS} onChange={(e)=>dispatch({type: 'LAYOUT_SET', payload: e.value})} />
    </div>
  );
}
