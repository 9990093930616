export default () => (
  <>
    <svg style={{display: "none"}}>
      <symbol id="control-prev" viewBox="0 0 24 24">
        <path d="M6 6h2v12H6zm3.5 6l8.5 6V6z" />
      </symbol>
      <symbol id="control-pause" viewBox="0 0 24 24">
        <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
      </symbol>
      <symbol id="control-play" viewBox="0 0 24 24">
        <path d="M8 5v14l11-7z" />
      </symbol>
      <symbol id="control-stop" viewBox="0 0 24 24">
        <path d="M6 6v12h12v-12z" />
      </symbol>
      <symbol id="control-next" viewBox="0 0 24 24">
        <path d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z" />
      </symbol>
      <symbol id="control-add" viewBox="0 0 24 24">
        <path d="M10 6h4v4h4v4h-4v4h-4v-4h-4v-4h4z" />
      </symbol>
      <symbol id="control-del" viewBox="0 0 24 24">
        <path d="M6 10h12v4h-12z" />
      </symbol>
    </svg>
  </>
)
