import './Album.css';
import logo from 'src/record.svg';
import { Link } from 'react-router5'
import {formatDuration, slugify} from 'src/utils';
import {getTracks, request} from 'src/Api';

export function fetchTracks(album_id, type, dispatch) {
  const {url, options} = getTracks(album_id);
  request(url, options)
    .then(payload => dispatch({type, payload: {tracks: payload}}))
    .catch(e => console.log(e))
}


export default function Album({album, showArtist, showType, dispatch}) {
  const artist = slugify(album.artist);

  return (
    <div>
      {showArtist && artist && (<h3><Link routeName="artist" routeParams={{artist}}>{album.artist}</Link></h3>)}
      {showType && (<h4>{album.type}</h4>)}
      <div className="Album">
        <img src={album.cover || logo} alt={album.album} />
        <span className="Album-year">{album.year}</span>
        <span className="Album-country">{album.country}</span>
        <span className="Album-tracks">{album.track_count}&nbsp;@&nbsp;{formatDuration(album.total_duration)}</span>
      {album.album && album.artist && <span className="Album-title"><Link routeName='album' routeParams={{artist, album: slugify(album.album)}}>{album.album}</Link></span>}
        <span className="Album-publisher">{album.publisher}</span>
        <span className="Album-genre">{album.genre}</span>
        <div className="Album-actions">
          <button onClick={(e)=>fetchTracks(album.id, 'ALBUM_PLAY', dispatch)}>
            <svg><use href="#control-play" /></svg>
          </button>
          <button onClick={(e)=>fetchTracks(album.id, 'ALBUM_ENQUEUE', dispatch)}>
            <svg><use href="#control-add" /></svg>
          </button>
        </div>
      </div>
    </div>
  )
}
