import InfiniteScroll from 'react-infinite-scroller';
import {useEffect} from 'react';
import {getAlbums, request} from 'src/Api';
import Album from '../Album';
import {CATEGORIES} from '../Filters';

function _albumProps(albums, index, props) {
  if (index === 0) {
    return {showArtist: !props.noArtist, showType: true}
  }

  if (albums[index-1].artist !== albums[index].artist) {
    return {showArtist: !props.noArtist, showType: true}
  }

  let showType = (albums[index-1].type !== albums[index].type);
  return {showType};
}


export function fetchAlbums(filters, page, dispatch) {
  const {filter, alpha} = filters;
  const limit  = 15;
  const offset = (page || 0) * limit;
  const params = {offset, limit:(limit+1)};
  if (filter) params.filter = filter;
  for (const {cat} of CATEGORIES) {
    if (filters[cat])
      params[cat] = filters[cat];
  }
  if (!alpha) params['latest'] = 1;
  const {url, options} = getAlbums(params);

  request(url, options)
    .then(data => {
      const more = data.length > limit;
      dispatch({type: 'LOAD_ALBUMS', payload:{items: data.slice(0, limit), more}});
    })
    .catch((e) => {
      console.log(e);
    })
}

export default function AlbumList(props) {
  const {albums, error, filters, dispatch, playerDispatch, scrollRef} = props;
  const title = (!props.noTitle && (filters.alpha ? 'Alphabetical' : 'Latest'));

  useEffect(()=>{
    fetchAlbums(filters, albums.page, dispatch);
  }, [filters, albums.page, dispatch]);

  return (
    <div className="AlbumList">
      {title && <h2>{title}</h2>}
      { error ? (
        <div className="error">{error}&gt;</div>
      ) : (
        <InfiniteScroll
          loadMore={(p) => {dispatch({type: 'SET_PAGE', payload: p})}}
          hasMore={albums.more}
          loader={<div className="loader" key={0}>Loading ...</div>}
          initialLoad={false}
          getScrollParent={() => scrollRef.current}
          useWindow={false}
          >
          {albums.items.map((album, idx) => (
              <Album key={idx} album={album} dispatch={playerDispatch} {..._albumProps(albums.items, idx, props)}/>
          ))}
        </InfiniteScroll>
      )
      }
    </div>
  );
}
