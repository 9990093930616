import './AlbumPage.css';
import { Link } from 'react-router5'
import {useRouteNode} from 'react-router5'
import {unslugify, formatDuration} from '../../utils.js';
import {useReducer, useEffect} from 'react';
import {fetchAlbums} from '../AlbumList';
import {fetchTracks} from '../Album';

function pageReducer(state, action) {
  switch (action.type) {
  case 'LOAD_ALBUMS':
    if (!action.payload.items || !action.payload.items.length)
      return {error: 'Not found'};
    const album = action.payload.items[0];
    return {...state, album};
  case 'SHOW_ALBUM':
    const tracks = action.payload.tracks;
    return {...state, tracks, loading: false};
  default: throw new Error(`Unknown action type: ${action.type}`);
  }
}

export default function AlbumPage({dispatch, scrollRef}) {
  const { route } = useRouteNode('album');
  const { artist: artistSlug, album: albumSlug } = route.params;
  const artistName = unslugify(artistSlug);
  const albumName = unslugify(albumSlug);
  const [{album, tracks, loading, error}, dispatchPage] = useReducer(pageReducer, {loading: true});

  useEffect(()=>{
    if (album)
      fetchTracks(album.id, 'SHOW_ALBUM', dispatchPage)
    else
      fetchAlbums({artist: artistName, album: albumName}, 0, dispatchPage);
  }, [artistName, albumName, album, dispatch]);

  return (
    <div className="AlbumPage">
      <h1>
        <Link routeName="artist" routeParams={{artist: artistSlug}}>
          {artistName}
        </Link>
      </h1>
      <h2>{albumName}</h2>{tracks && (<div className="Album-actions">
          <button onClick={(e)=>dispatch({type:'ALBUM_PLAY', payload: {tracks, pos: 0}})}>
            <svg><use href="#control-play" /></svg>
          </button>
          <button onClick={(e)=>dispatch({type: 'ALBUM_ENQUEUE', payload: {tracks}})}>
            <svg><use href="#control-add" /></svg>
          </button>
      </div>)}
      {album && (<>
        {album.date && <h3>Date: {album.year}</h3>}
        {album.country && <h3>Country: {album.country}</h3>}
        {album.track_count && <h3>Tracks: {album.track_count}</h3>}
        {album.total_duration && <h3>Duration: {formatDuration(album.total_duration)}</h3>}
        {album.publisher && <h3>Publisher: {album.publisher}</h3>}
        {album.genre && <h3>Genre: {album.genre}</h3>}
      </>)}
      <Link routeName="browser">Browse</Link>
      {loading && <h3>Loading...</h3>}
      {error && <h3>{ error }</h3>}
    {tracks && (
      <table><tbody>
          {tracks.map((track, i) => (
            <tr key={i}>
              <td>{track.no}.</td>
              <td>{track.title}</td>
              <td>{track.bit_rate} kbps</td>
              <td>{formatDuration(track.duration)}</td>
              <td>
                <button onClick={() => dispatch({type:'ALBUM_PLAY', payload: {tracks, pos: i}})}>
                  <svg><use href="#control-play" /></svg>
                </button>
                <button onClick={(e)=>dispatch({type: 'QUEUE_ADD', payload: track})}>
                  <svg><use href="#control-add" /></svg>
                </button>
              </td>
            </tr>))
          }
      </tbody></table>
    )}
    </div>
  );
}
