import { Link } from 'react-router5'
import {useRouteNode} from 'react-router5'
import {unslugify} from '../utils'
import {useReducer} from 'react';
import AlbumList from './AlbumList';
import {browserReducer, ALBUMS_EMPTY} from './Browser';

export default function ArtistPage({dispatch, scrollRef}) {
  const { route } = useRouteNode('artist');
  const artist = unslugify(route.params.artist);
  const INIT = {albums: ALBUMS_EMPTY, error: false, filters: {artist, alpha:true}};
  const [state, dispatchBrowser] = useReducer(browserReducer, INIT);

  return (
    <>
      <h1 className="ArtistName">{artist}</h1><Link routeName="browser">Browse</Link>
      <AlbumList {...state} dispatch={dispatchBrowser} playerDispatch={dispatch} scrollRef={scrollRef} noTitle noArtist/>
    </>
  );
}
