import {useReducer} from 'react';
import './App.css';
import {storeToken, loadToken} from 'src/Api';
import Login from '../Login';
import Header from '../Header';
import Player from '../Player';
import SvgSymbols from '../SvgSymbols';

function storeSettings(settings) {
  localStorage.setItem('settings', JSON.stringify(settings));
  return settings;
}
function loadSettings() {
  const settings = localStorage.getItem('settings');
  return (settings && JSON.parse(settings)) || {login:{}, theme:'light', layout:'two-col', panel:'main'};
}

function settingsReducer(state, action) {
  console.log(action);
  switch(action.type) {
  case 'LOGIN_SUCCESS':
    storeToken(action.payload.token);
    return {...state, login: {loading: false, error: null}};
  case 'LOGIN_INIT': return {...state, login: {loading: true, error: null}};
  case 'LOGIN_FAIL': return {...state, login: {loading: false, error: action.payload}};
  case 'LAYOUT_SET': return storeSettings({...state, layout: action.payload});
  case 'THEME_SET':  return storeSettings({...state, theme: action.payload});
  case 'PANEL_SET':  return storeSettings({...state, panel: action.payload});
  default: throw new Error(`Bad action ${action}`);
  }
}

const handleRadio = (dispatch, type) => (e) => dispatch({type:`${e.target.name.toUpperCase()}_SET`, payload: e.target.value});

function App() {
  const [settings, dispatch] = useReducer(settingsReducer, loadSettings());
  if(!loadToken()) {
    return <Login state={settings} dispatch={dispatch} />
  }

  return (
    <div className="App" data-theme={settings.theme} data-layout={settings.layout} data-panel={settings.panel || 'main'}>
      <SvgSymbols />
      <Header settings={settings} dispatch={dispatch} />
      <div className="App-panels">
        <input id="panel-main" type="radio" name="panel" value="main" checked={settings.panel === 'main'} onChange={handleRadio(dispatch)} />
        <input id="panel-side" type="radio" name="panel" value="side" checked={settings.panel === 'side'} onChange={handleRadio(dispatch)} />
        <label htmlFor="panel-main">Browse</label>
        <label htmlFor="panel-side">Queue</label>
      </div>
      <Player />
    </div>
  );
}

export default App;
