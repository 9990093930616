import {useEffect, useState} from 'react';
import {getQueryString} from './utils';

const domain = '';

export function storeToken(token) {
  localStorage.setItem('token', token);
}

export function loadToken() {
  return localStorage.getItem('token');
}

export function login(info) {
  if (!info) return null;

  return {url: '/api/login',
          options: {
            method: 'POST',
            body: JSON.stringify(info),
          }};
}

export function logout() {
  localStorage.removeItem('token');
}

export function getUser() { return {url: '/api/user'} };
export function getCategories() { return {url: '/api/cat'} };
export function getCategory(cat, params) {
  return {url: `/api/cat/${cat}?${getQueryString(params)}`};
}
export function getAlbums(params) {
  return {url: `/api/cat/album?${getQueryString(params)}`};
}
export function getTracks(album_id) {
  return {url: `/api/album/${album_id}/tracks`};
}

export const initialState = (initialData) => {return {loading: false, error: null, data: initialData}}
export function fetchReducer(s, a) {
  switch (a.type) {
  case 'FETCH_INIT': return {...s, loading: true, error: null};
  case 'FETCH_SUCCESS': return {...s, loading: false, error: null, data: a.payload};
  case 'FETCH_FAIL': return {...s, loading: false, error: a.payload};
  default: throw new Error();
  }
}

export const useFetch = (initialUrl, dispatch, typePrefix) => {
  const [url, setUrl] = useState(initialUrl);
  const prefix = typePrefix || 'FETCH';

  useEffect(() => {
    if (!url) return;
    let mounted = true;
    const fetchData = async () => {
      dispatch({type:`${prefix}_INIT`});
      try {
        const data = await request(url.url, url.options);
        mounted && dispatch({type:`${prefix}_SUCCESS`, payload: data});
      } catch (err) {
        mounted && dispatch({type:`${prefix}_FAIL`, payload: err});
      }
    };
    fetchData();
    return () => { mounted = false; };
  }, [url,prefix,dispatch]);

  return setUrl;
};

export function request(url, options) {
  // performs api calls sending the required authentication headers
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  // Setting Authorization header
  // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
  if (loadToken()) {
    headers['Authorization'] = 'Bearer ' + loadToken();
  }

  return fetch(`${domain}${url}`, {
    headers,
    ...options,
  })
    .then(_checkStatus)
    .then(response => response.json());
}

function _checkStatus(response) {
  // raises an error in case response status is not a success
  if (response.status >= 200 && response.status < 300) {
    // Success status lies between 200 to 300
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}
