import { Link } from 'react-router5'
import './Queue.css';
import {slugify, formatDuration} from 'src/utils';

export default function Queue({queue, dispatch}) {
  return (
    <aside className="Queue">
      <h2>Queue</h2>
      <table><tbody>
          {queue.items.map((track, i) => (
            <tr key={i} className={ i === queue.pos ? "Queue-active" : "" }>
              <td>{i+1}</td>
              <td>{track.title}</td>
              <td>{(track.album_artist || track.artist) && <Link routeName="artist" routeParams={{artist: slugify(track.album_artist || track.artist)}}>{track.artist}</Link>}</td>
              <td>{track.year}</td>
              <td>{track.album && <Link routeName="album" routeParams={{artist: slugify(track.album_artist || track.artist), album: slugify(track.album)}}>{track.album}</Link>}</td>
              <td>{track.no}</td>
              <td>{track.bit_rate} kbps</td>
              <td>{formatDuration(track.duration)}</td>
              <td>
                <button onClick={() => dispatch({type:'QUEUE_PLAY', payload: i})}>
                  <svg><use href="#control-play" /></svg>
                </button>
                <button onClick={() => dispatch({type:'QUEUE_DELETE', payload: i})}>
                  <svg><use href="#control-del" /></svg>
                </button>
              </td>
            </tr>))}
      </tbody></table>
    </aside>
  );
}
