import './Controls.css';
import logo from 'src/record.svg';
import Sound from 'react-sound';
import {slugify,formatDuration} from 'src/utils';

export default function Controls({queue, error, sound, dispatch}) {
  const track = queue.items[queue.pos] || {};
  const playing = sound.playStatus === Sound.status.PLAYING;
  const elapsed = sound.position ? formatDuration(sound.position/1000) : '';
  const duration = sound.duration ? formatDuration(sound.duration/1000) : '';

  return (
    <footer className="Controls">
      <div className="Controls-progress">
        <progress className="Controls-bar" value={sound.position} min="0" max={sound.duration}></progress>
      </div>
      {duration && <span className="Controls-time">{elapsed} / {duration}</span>}
      {error && <span className="Controls-error">{error.code}: {error.desc}</span>}
      <img src={track.cover || logo} alt={track.album} />
      <div className="Controls-artist"><a href={`/${slugify(track.album_artist)}/`}>{track.artist}</a></div>
      <div className="Controls-album"><a href={`/${slugify(track.album_artist)}/${slugify(track.album)}`}>{track.album}{track.year && ` [${track.year}]`}</a></div>
      <div className="Controls-title">{track.no && `${track.no}. `}{track.title}</div>
      <div className="Controls-buttons">
        <button disabled={queue.pos < 1} onClick={()=>dispatch({type: 'CONTROL_PREV'})}>
          <svg><use href="#control-prev" /></svg>
        </button>
        <button disabled={queue.pos < 0}
                onClick={()=>dispatch({type: (playing ? 'CONTROL_PAUSE' : 'CONTROL_PLAY')})}>
          {playing
            ? <svg><use href="#control-pause" /></svg>
            : <svg><use href="#control-play" /></svg>
          }
        </button>
        <button disabled={sound.playStatus === Sound.status.STOPPED}
                onClick={()=>dispatch({type: 'CONTROL_STOP'})}>
          <svg><use href="#control-stop" /></svg>
        </button>
        <button disabled={(queue.pos+1) >= queue.items.length} onClick={()=>dispatch({type: 'CONTROL_NEXT'})}>
          <svg><use href="#control-next" /></svg>
        </button>
      </div>
    </footer>
  );
}
