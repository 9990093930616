import './Header.css';
import logo from 'src/chad-logo-256.png';
import { BaseLink, useRoute } from 'react-router5'
import Settings from '../Settings';
import UserInfo from '../UserInfo';

function handleSearch(e, router) {
  if (e.charCode === 13) router.navigate('browser', {filter: e.target.value});
}

export default function Header({settings, dispatch}) {
  const { router } = useRoute()
  const { name, params } = router.getState();

  return (
    <header className="Header">
      <BaseLink className="Header-logo" router={router} routeName="browser" routeOptions={{ reload: true }}>
        <img src={logo} alt="Chad music" />
      </BaseLink>
      <h1 className="Header-title">Chad Music</h1>
      <input className="Header-search" type="text"
             placeholder="Search albums"
             defaultValue={name === "browser" ? params.filter : ''}
             onKeyPress={(e) => handleSearch(e, router)}/>
      <Settings state={settings} dispatch={dispatch} />
      <UserInfo />
    </header>
  );
}
