import './Login.css';
import TelegramLoginButton from '../TelegramLoginButton';
import {login, useFetch} from 'src/Api';

export default function Login({state, dispatch}) {
  const setUrl = useFetch(null, dispatch, 'LOGIN');
  const doLogin = (info) => setUrl(login(info));

  return (
    <div className="Login">
      {state.login.error && <div className="Login-error">{state.login.error.message}</div>}
      {state.login.loading && <div>Loading...</div>}
      <TelegramLoginButton botName="ChadPartners_bot" dataOnAuth={doLogin} />
    </div>
  );
}
